<template>
  <v-container class="ma-0 pa-0 div66">
    <div class="flex-grow-1 div3">
      <slot name="content" />
    </div>

    <div class="flex-grow-0 div7">
      <slot name="bottom" />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'LayoutBottomBtn'
}
</script>

<style scoped>
  .div66 {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    flex-direction: column;
  }
  .div3 {
    flex-grow: 1;
    overflow: auto;
    position: relative;
  }
  .div7 {
    flex-grow:0;
  }
</style>
