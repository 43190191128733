import _ from 'lodash'

export const timerState = {
  running: 'timer.running',
  paused: 'timer.paused',
  stopped: 'timer.stopped',
  closed: 'timer.closed'
}

export default {
  data: () => ({
    timerState: timerState.stopped,
    timestamp: 0,
    elapsed: 0,
    round: null,
    modals: [],
    warnings: [],
    currentStepId: 0,
    steps: [],
    additionalSteps: []
  }),

  computed: {
    currentElapsed () {
      const current = this.elapsed - _.sum(this.round.steps.filter(
        step => this.steps.findIndex(s => s.state === step.state) > 0 &&
        step.state === this.currentStep.state
      ).map(s => s.elapsed))

      if (this.currentStep.modifiers !== undefined) {
        return current - _.sum(this.currentStep.modifiers.map((m) => {
          const steps = this.round.steps.filter(s => s.state === m.state)
          const total = steps.map((s) => {
            let duration = this.timestamp - s.timestamp

            if (s.results) {
              const result = s.results.find(r => r.type === `${s.state}.duration`)

              if (result) {
                duration = result.value
              }

              return duration >= m.limit * 1000 ? duration : 0
            }

            return duration
          })

          return _.sum(total)
        }))
      }

      return current
    },

    roundElapsed () {
      if (!this.round) {
        return 0
      }

      const firstStep = this.round.steps[0]
      if (!firstStep) {
        return 0
      }

      const endings = this.test.steps.map(s => s.timer === 'close' ? s.state : null).filter(s => s)
      const lastStep = this.round.steps.find(s => ['abort', ...endings].includes(s.state))

      const lastTimestamp = lastStep ? lastStep.timestamp : this.timestamp
      return lastTimestamp - firstStep.timestamp
    },

    globalElapsed () {
      const firstRound = this.registration.rounds[0]

      if (!firstRound) {
        return 0
      }

      const firstStep = firstRound.steps[0]
      if (!firstStep) {
        return 0
      }

      let lastStep = null
      const endings = this.test.steps.map(s => s.timer === 'close' ? s.state : null).filter(s => s)

      for (let i = this.registration.rounds.length - 1; i >= 0; --i) {
        const lastRound = this.registration.rounds[i]
        if (lastRound.steps.length === 0) {
          continue
        }

        lastStep = lastRound.steps.find(s => ['abort', ...endings].includes(s.state))
        break
      }

      const lastTimestamp = lastStep ? lastStep.timestamp : this.timestamp
      return lastTimestamp - firstStep.timestamp
    },

    currentStep () {
      if (this.timerState === timerState.closed) {
        return this.steps[this.steps.length - 1]
      }

      return this.steps[this.currentStepId]
    },

    event () {
      return this.$store.state.rx.events.find(
        e => e.id === this.$route.params.eventId
      )
    },

    registration () {
      return this.$store.state.rx.registrations.find(
        r => r.id === this.$route.params.registrationId
      )
    },

    test () {
      return this.$store.state.rx.tests.find(
        t => t.type === this.event.type
      )
    },

    roundId () {
      return Number(this.$route.params.roundId)
    },

    ready () {
      return this.round !== null && this.currentStep !== undefined
    },

    listeners () {
      return {
        addModal: this.addModal,
        addModalAdditional: this.addModalAdditional,
        reorderSteps: this.reorderSteps,
        editModal: this.editModal,
        abortModal: this.abortModal,
        onCloseModal: this.onCloseModal,
        barkFrequencyModal: this.barkFrequencyModal,
        goBack: this.goBack
      }
    }
  },

  mounted () {
    this.steps = [
      {
        state: 'init',
        swapState: false
      },
      ...this.test.steps ?? []
    ]

    this.additionalSteps = [
      {
        state: 'abort',
        timer: 'close',
        title: 'Abort',
        display: false,
        before: true,
        evaluation: {
          inputs: [
            {
              scope: 'comment',
              type: 'comment',
              value: 'Comment'
            }
          ]
        }
      },
      ...this.test.additional_steps.map((s) => {
        if (s.duration !== true) {
          return {
            ...s,
            additional: true
          }
        }

        const evaluation = {
          inputs: []
        }

        if (s.evaluation && s.evaluation.inputs) {
          evaluation.inputs = s.evaluation.inputs
        }

        evaluation.inputs.push({
          scope: `${s.state}.duration`,
          type: 'duration',
          value: 0
        })

        return {
          ...s,
          evaluation,
          additional: true
        }
      }) ?? []
    ]

    this.lastTick = this.$nosework.timestamp
    this.timestamp = this.$nosework.timestamp

    this.$nosework.watch('timestamp', (value) => {
      if (
        this.timerState === timerState.running &&
        this.timestamp > 0 &&
        value > 0
      ) {
        const tick = (value - this.timestamp)
        this.elapsed += tick
        this.totaltime += tick

        this.checkDurationAlerts()
      }

      this.timestamp = value
    })

    this.round = this.registration.rounds[this.roundId]
    this.switchToLastStep(true)
  },

  methods: {
    elapsedBySteps (from, to, round = null) {
      let current = round
      if (!current) {
        current = this.round
      }
      const ss = current.steps.find(s => s.state === from)
      const es = current.steps.find(s => s.state === to)

      if (!ss) {
        return 0
      }

      let end = this.elapsed

      if (es) {
        end = es.elapsed
      }

      let calc = end - ss.elapsed

      const step = this.steps.find(s => s.state === from)

      if (step.modifiers !== undefined) {
        calc -= _.sum(step.modifiers.map((m) => {
          const steps = current.steps.filter(s => s.state === m.state)
          const total = steps.map((s) => {
            let duration = this.timestamp - s.timestamp

            if (s.results) {
              const result = s.results.find(r => r.type === `${s.state}.duration`)

              if (result) {
                duration = result.value
              }

              return duration >= m.limit * 1000 ? duration : 0
            }

            return duration
          })

          return _.sum(total)
        }))
      }

      return calc
    },

    globalElapsedBySteps (from, to) {
      return _.sum(this.registration.rounds.map(
        r => this.elapsedBySteps(from, to, r)
      ))
    },

    remainingTestTime (time) {
      const total = 3600000 * time
      let calc = 0

      if (this.currentStep.state === 'upptag') {
        calc = total - this.currentElapsed
      } else {
        calc = total - this.elapsedBySteps('upptag', 'inkall')
      }

      if (calc <= 0) {
        this.warnTestTime = true
        calc = 0
      } else if (this.warnTestTime) {
        this.warnTestTime = false
      }

      return calc
    },

    globalRemainingTestTime (time) {
      const total = 3600000 * time
      const calc = total - this.globalElapsedBySteps('upptag', 'inkall')
      return calc > 0 ? calc : 0
    },

    checkDurationAlerts () {
      this.additionalSteps.filter(
        s => s.duration === true &&
        !this.warnings.includes(s.state) &&
        s.warning !== undefined
      ).forEach((s) => {
        const total = _.sum(this.round.steps.filter(c => c.state === s.state).map((s) => {
          let duration = this.timestamp - s.timestamp

          if (s.results) {
            const result = s.results.find(r => r.type === `${s.state}.duration`)

            if (result) {
              duration = result.value
            }
          }

          return duration
        }))

        if (total > s.warning.duration * 1000) {
          this.warnings.push(s.state)

          if (s.warning.alert) {
            this.modals.unshift({
              type: 'alert-modal',
              options: {
                title: this.$t(s.warning.alert.title),
                text: this.$t(s.warning.alert.text),
                onClose: () => {}
              }
            })
          }
        }
      })
    },

    switchToLastStep (setElapsed = false) {
      // const first = _.first(this.round.steps)
      const last = _.last(this.round.steps)
      const lastStep = _.last(this.round.steps.filter(
        step => this.steps.findIndex(s => s.state === step.state) > 0
      ))

      if (last && lastStep) {
        if (setElapsed) {
          if (last.state === 'pause') {
            this.elapsed = last.elapsed
          } else if (last.state === 'stop' || last.state === 'abort') {
            this.elapsed = last.elapsed
            this.timerState = 'timer.closed'
            return
          } else {
            const tick = (this.timestamp - last.timestamp)
            this.elapsed = last.elapsed + tick
          }
        }

        this.currentStepId = this.steps.findIndex((s) => {
          return s.state === lastStep.state
        })

        const steps = this.steps.map(s => s.state)
        const rsteps = _.reverse(_.cloneDeep(this.round.steps))
        const lastPause = rsteps.findIndex(s => s.state === 'pause')
        const lastResume = rsteps.findIndex(
          s => s.state === 'restart' || steps.includes(s.state)
        )

        this.timerState = (lastResume > lastPause && lastPause !== -1) ? 'timer.paused' : 'timer.running'
      } else {
        this.timerState = 'timer.paused'
        this.currentStepId = 0
        this.elapsed = 0
      }
    },

    onCloseModal () {
      this.modals.pop()
    },

    addModal () {
      this.modals.unshift({
        type: 'add-edit-modal',
        options: {
          new: true,
          roundSteps: this.round.steps,
          steps: this.steps,
          currentStepId: this.currentStepId,
          onClose: async (step) => {
            if (!step) {
              return
            }

            await this.pushState(step.state, false)
            await this.setStep(step)
          }
        }
      })
    },

    addModalAdditional (step) {
      this.modals.unshift({
        type: 'add-edit-modal',
        options: {
          new: true,
          roundSteps: this.round.steps,
          steps: [
            ...this.additionalSteps,
            ...this.steps
          ],
          currentStep: step,
          onClose: async (ret) => {
            if (!ret) {
              return
            }

            // await this.pushState(ret.state, false)
            try {
              await this.setStep(ret)
              await this.reorderSteps(step)
            } catch (err) {
              console.log(err)
            }
          }
        }
      })
    },

    editModal (index) {
      this.modals.unshift({
        type: 'add-edit-modal',
        options: {
          roundSteps: this.round.steps,
          steps: [
            ...this.additionalSteps,
            ...this.steps
          ],
          selectedIndex: index,
          onClose: async (step) => {
            if (!step) {
              return
            }

            if (step.delete) {
              await this.deleteStep(step)
            } else {
              try {
                await this.setStep(step, this.timerState === timerState.closed)

                /* if (!['pause', 'restart', 'init'].includes(step.state)) {
                  return
                } */

                await this.reorderSteps(step)
              } catch (err) {
                console.log(err)
              }
            }
          }
        }
      })
    },

    async reorderSteps (step) {
      const round = this.registration.rounds[this.roundId]
      round.steps.sort((a, b) => a.timestamp - b.timestamp)

      await this.registration.atomicUpdate((reg) => {
        reg.rounds[this.roundId].steps = round.steps.map((s, si) => {
          if (s.timestamp <= step.timestamp || s.state === 'restart') {
            return s
          }

          return {
            ...s,
            index: si,
            elapsed: this.$nosework.elapsed(s, round.steps, si)
          }
        })

        // Need to calculate restarts after pause updates
        reg.rounds[this.roundId].steps = round.steps.map((s, si) => {
          if (s.timestamp <= step.timestamp || s.state !== 'restart') {
            return s
          }

          return {
            ...s,
            index: si,
            elapsed: this.$nosework.elapsed(s, round.steps, si)
          }
        })

        return reg
      })

      this.round = this.registration.rounds[this.roundId]
      this.elapsed = (this.timestamp - _.last(this.round.steps).timestamp) +
        _.last(this.round.steps).elapsed
    },

    evaluationModal (step, data = {}) {
      this.modals.unshift({
        type: 'evaluation-modal',
        options: {
          state: step.state,
          title: step.title,
          evaluation: step.evaluation,
          onClose: async (results) => {
            this.modal = null

            if (!results || results.value < 0) {
              return
            }

            await this.setStep({
              state: step.state,
              ...data,
              results
            })
          }
        }
      })
    },

    barkFrequencyModal (scope) {
      const result = this.round.results.find(r => r.type === scope)

      this.modals.unshift({
        type: 'bark-frequency-modal',
        options: {
          title: 'barkFrequency',
          result,
          onClose: async (bpm) => {
            if (bpm === null) {
              return
            }

            await this.registration.atomicUpdate((reg) => {
              const round = reg.rounds[this.roundId]

              round.results = [
                ...round.results.filter(r => r.type !== scope),
                {
                  type: scope,
                  value: bpm ?? 0,
                  value_text: `${bpm ?? 0}`,
                  comment: ''
                }
              ]
              return reg
            })

            this.round = this.registration.rounds[this.roundId]
          }
        }
      })
    },

    goBack () {
      this.$router.push(`/event/${
        this.$route.params.eventId
      }/registration/${
        this.$route.params.registrationId
      }`)
    },

    abortModal () {
      this.modals.unshift({
        type: 'confirmation-modal',
        options: {
          title: this.$t('Are you sure to abort the round?'),
          text: this.$t('This will remove all the steps from the current round.'),
          onClose: (abort) => {
            if (abort) {
              this.resetRound().then(() => {
                return this.$router.push(`/event/${
                    this.$route.params.eventId
                  }/registration/${
                    this.$route.params.registrationId
                  }`)
              }).catch((err) => {
                console.log(err)
              })
            }
          }
        }
      })
    },

    switchTimerState (state) {
      switch (state) {
        case 'toggle':
          this.timerState = this.timerState === timerState.paused
            ? timerState.resume
            : timerState.paused
          break
        case 'close':
          this.timerState = timerState.closed
          break
        case 'pause':
          this.timerState = timerState.paused
          break
        default:
        case 'resume':
          this.timerState = timerState.running
          break
      }
    },

    async resetRound () {
      try {
        await this.registration.atomicUpdate((reg) => {
          reg.rounds[this.roundId].steps = []
          return reg
        })
      } catch (err) {
        console.log(err)
      }
    },

    async setStep (step, setElapsed = false) {
      try {
        await this.registration.atomicUpdate((reg) => {
          let id = -1
          const additional = this.additionalSteps.findIndex(s => s.state === step.state) > -1

          if (additional) {
            id = step.index ?? -1
          } else if (this.steps.findIndex(s => s.state === step.state) > -1) {
            id = reg.rounds[this.roundId].steps.findIndex(
              s => s.state === step.state
            )
          }

          if (id < 0) {
            step.index = reg.rounds[this.roundId].steps.push(step)
            return reg
          }

          reg.rounds[this.roundId].steps[id] = {
            ...reg.rounds[this.roundId].steps[id],
            ...step
          }

          reg.rounds[this.roundId].steps = _.orderBy(
            reg.rounds[this.roundId].steps,
            ['timestamp'],
            ['asc']
          )

          return reg
        })
      } catch (err) {
        console.log(err)
      }

      this.round = this.registration.rounds[this.roundId]

      if (setElapsed) {
        this.elapsed = _.last(this.round.steps).elapsed
      }
    },

    stopRunningDurations (parent) {
      const durationSteps = this.additionalSteps.filter(
        s => s.duration === true
      )
      const durationStepStates = durationSteps.map(s => s.state)
      const durations = this.round.steps.filter(
        s => durationStepStates.includes(s.state)
      )

      return durations.map((selected) => {
        const current = durationSteps.find(s => s.state === selected.state)
        if (selected && !current.when.includes(parent)) { // Running duration
          const { state } = selected

          let results = [
            {
              type: `${state}.duration`,
              value: this.elapsed - selected.elapsed,
              value_text: '',
              comment: ''
            }
          ]

          if (selected.results) {
            const result = selected.results.find(r => r.type === `${state}.duration`)

            if (!result) {
              results = [
                ...selected.results,
                ...results
              ]
            } else {
              results = null // Stopped duration
            }
          }

          if (results) {
            return this.setStep({
              ...selected,
              results
            })
          }
        }

        return null
      })
    },

    async deleteStep (step) {
      try {
        await this.registration.atomicUpdate((reg) => {
          reg.rounds[this.roundId].steps.splice(step.index, 1)
          return reg
        })
      } catch (err) {
        console.log(err)
      }

      this.round = this.registration.rounds[this.roundId]
      this.switchToLastStep(true)
    },

    findLastStepByType (type) {
      return _.last(this.round.steps.filter(s => s.state === type))
    },

    async pushState (state, notify = true) {
      let step = this.steps.find(s => s.state === state)

      if (step === undefined) {
        step = this.additionalSteps.find(s => s.state === state)
        if (step !== undefined) {
          step.swapState = false
        }
      }

      if (step === undefined) {
        return
      }

      if (step.duration) {
        const selected = this.findLastStepByType(state)

        if (selected) { // Running duration
          let results = [
            {
              type: `${state}.duration`,
              value: this.elapsed - selected.elapsed,
              value_text: '',
              comment: ''
            }
          ]

          if (selected.results) {
            const result = selected.results.find(r => r.type === `${state}.duration`)

            if (!result) {
              results = [
                ...selected.results,
                ...results
              ]
            } else {
              results = null // Stopped duration
            }
          }

          if (results) {
            return await this.setStep({
              ...selected,
              results
            })
          }
        }
      }

      if (step.timer || !step.timestamp) {
        this.switchTimerState(step.timer)
      }

      const lastStep = this.steps[this.currentStepId]
      let index = this.round.steps.length
      if (step.unique) {
        index = this.round.steps.findIndex(s => s.state === step.state)
      }

      const data = {
        index,
        state: step.state,
        timer: this.timerState,
        elapsed: this.elapsed,
        timestamp: Math.trunc((this.$nosework.timestamp / 1000)) * 1000
      }

      let swapped = false

      if (step.swapState === undefined || !step.swapState === false) {
        this.currentStepId += 1
        swapped = true
      }

      if (step.store === undefined || !step.store === false) {
        if (notify) {
          if (
            lastStep.evaluation !== undefined &&
            !lastStep.evaluation.before && swapped
          ) {
            this.evaluationModal(lastStep)
          }

          if (step.evaluation !== undefined) {
            if (step.evaluation.before && swapped) {
              this.evaluationModal(step)
            }

            if (step.evaluation.instant) {
              return this.evaluationModal(step, data)
            }
          }
        }

        if (swapped) {
          // stop running duration steps based on when
          await this.stopRunningDurations(state)
        }

        await this.setStep(data)
      }
    }
  }
}
